import { CONNECTION } from '../../config/Connection';
import { CONFIG_, CONFIG_DOWNLOAD } from '../../config/Config';
// import fileDownload from 'js-file-download';
import { __openModal } from '../modal';
import { getFilename } from '../../config/Helper';
import * as Sentry from '@sentry/react';
var post = '';
export const __getFileWithoutPassword = (data) => {
  return (dispatch) => {
    const { file_url, content_type, is_secured, username, password, file_name } = data;
    console.log('🚀 ~ return ~ file_url:', file_url);
    const filename = getFilename(file_url);
    const file = file_name === '' ? filename : file_name;
    let config = CONFIG_DOWNLOAD({
      url: file_url,
      method: 'GET',
      contentType: content_type,
    });

    if (is_secured) {
      config = CONFIG_DOWNLOAD({
        url: file_url,
        method: 'GET',
        contentType: content_type,
        auth: {
          username: username,
          password: password,
        },
      });
    }

    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code !== 500) {
          // fileDownload(response, file);
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.setAttribute('hidden', '');
          a.setAttribute('href', url);
          a.setAttribute('download', file);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          dispatch(__openModal({ modal: 'FORM_DOWNLOAD', open: false }));
          return dispatch({
            type: 'SUCCESS_DOWNLOAD',
            message: 'Success post data',
            code: 200,
          });
        }
        return dispatch({
          type: 'SUCCESS_DOWNLOAD',
          message: 'File download error, Please check your username and password',
          code: code,
        });
      })
      .catch((response) => {
        // console.log(response);
        Sentry.captureException(response, {
          extra: {
            file_name: file_name,
          },
        });
        return dispatch({ type: 'ERROR_DOWNLOAD', message: 'Network error..' });
      });
  };
};

export const _downloadFileWithPassword = (params) => {
  console.log('🚀 ~ const_downloadFileWithPassword= ~ params:', params);
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST' });
    dispatch({ type: 'LOADING_DOWNLOAD' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          return dispatch({
            type: 'SUCCESS_DOWNLOAD',
            message: 'Success post data',
          });
        }
        return dispatch({ type: 'ERROR_DOWNLOAD', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_DOWNLOAD', message: 'Network error..' });
      });
  };
};
